<template>
  <div>
    <div v-if="item.customer_order_ids">
      <OrderTracking
        v-if="!item.customer_order_ids.includes(';')"
        :tracking-id="item.customer_order_ids"
      />
      <span
        v-else
        class="primary--text text-decoration-underline cursor-pointer"
        @click="showDialogOrderList"
      >
        Nhiều mã
      </span>
    </div>

    <v-dialog
      v-model="dialogOrderList"
      max-width="480px"
      v-if="dialogOrderList"
    >
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("labels.customer_order_id") }}
        </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col cols="6" v-for="orderId in orderIds" :key="orderId">
              <OrderTracking :tracking-id="orderId" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "InvoiceTracking",
  components: {
    OrderTracking: () => import("@/components/common/OrderTracking"),
  },
  props: {
    item: {
      type: Object,
      default: () => "",
    },
  },
  data: () => ({
    dialogOrderList: false,
  }),
  computed: {
    orderIds() {
      return this.item.customer_order_ids.split(";");
    },
  },
  methods: {
    showDialogOrderList() {
      this.dialogOrderList = true;
    },
  },
};
</script>

<style scoped></style>
